// framework
import { useState, useEffect, ReactElement } from "react";
import { IoAddCircleOutline, IoCheckmarkCircle } from "react-icons/io5";
// kendo
import { Grid, GridColumn, GridDataStateChangeEvent, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { DataResult, State, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { DateFilter, FilterChangeEvent } from "@progress/kendo-react-data-tools";
// models
import { IReprocessingSearchDataRow } from "./models";
// common
import ODataReader from "../../../common/odata/ODataReader";
import * as GlobalHelpers from "../../../common/GlobalHelpers";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";
import ListSummaryView from "../../../common/listSummary/listSummaryView";
import CommentSummaryView from "../../../common/commentSummary/commentSummaryView";
import * as StringHelper from "../../../common/stringHelper";
import GridFilter from "../../../common/kendoGrid/GridFilter";
import { getDateFilter, getTextFilter } from "../../../common/kendoGrid/GridFilterOptions";
import { TextBoxFilter } from "../../../common/kendoGrid/GridFilterTextBoxFilter";
import CopyODataLinkView from "../../../common/odata/CopyODataLinkView";

interface IProps {
    selectedItems: Array<IReprocessingSearchDataRow>;
    onAdd: (items: Array<IReprocessingSearchDataRow>) => void;
    onRemove: (item: IReprocessingSearchDataRow) => void;
}

export default function Controller(props: IProps) {
    const [pageSize, setPageSize] = useState(10);
    const maxTextLength = 100;
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
            {
                field: "ReprocessingName",
                operator: "contains",
                value: "",
            },
        ],
    };

    const [gridState, setGridDataState] = useState<State>({
        sort: [{ field: "BasicReleaseDate", dir: "desc" }],
        take: pageSize,
        skip: 0,
        filter: initialFilter,
    });

    const [filter, setFilter] = useState<CompositeFilterDescriptor>(initialFilter);
    const onFilterChange = (event: FilterChangeEvent) => {
        setFilter(event.filter);
        setGridDataState({ ...gridState, filter: event.filter });
    };

    const [data, setData] = useState<DataResult>({ data: [], total: 0 });
    function onDataChange(data: DataResult): void {
        setData(data);
    }

    function mapDataRow(dataRow: any): IReprocessingSearchDataRow {
        return {
            ReprocessingProjectId: dataRow.ReprocessingProjectId,
            ReprocessingProjectIdentifier: dataRow.ReprocessingProjectIdentifier,
            ReprocessingProjectName: dataRow.ReprocessingProjectName,
            ReprocessingId: dataRow.ReprocessingId,
            ReprocessingIdentifier: dataRow.ReprocessingIdentifier,
            ReprocessingName: dataRow.ReprocessingName,
            AcquisitionAliases: dataRow.AcquisitionAliases,
            ReprocessingOperator: dataRow.ReprocessingOperator,
            ReprocessingType: dataRow.ReprocessingType,
            BasicRelevantDate: dataRow.BasicRelevantDate ? new Date(dataRow.BasicRelevantDate) : null,
            InterpretativeRelevantDate: dataRow.InterpretativeRelevantDate ? new Date(dataRow.InterpretativeRelevantDate) : null,
            BasicReleaseDate: dataRow.BasicReleaseDate ? new Date(dataRow.BasicReleaseDate) : null,
            InterpretativeReleaseDate: dataRow.InterpretativeReleaseDate ? new Date(dataRow.InterpretativeReleaseDate) : null,
            RelatedAcquisitions: dataRow.RelatedAcquisitions,
            RelatedBasins: dataRow.RelatedBasins,
            RelatedTitles: dataRow.RelatedTitles,
            Comments: dataRow.Comments,
        };
    }

    // ensure we don't get stuck on an empty grid page
    useEffect(() => {
        if (data.data.length === 0 && data.total > 0 && gridState && gridState.skip! > 0) setGridDataState({ ...gridState, skip: 0 });
    }, [data, gridState]);

    function compare(a: string, b: string): number {
        return a! < b! ? -1 : 1;
    }

    function generateListSummaryViewChild(name: string, truncatedDisplayName?: string): ReactElement {
        return <span>{truncatedDisplayName ?? name}</span>;
    }

    // return
    return (
        <>
            <div className="row">
                <div className="col">
                    <GridFilter
                        value={filter}
                        onChange={onFilterChange}
                        fields={[
                            { name: "ReprocessingIdentifier", label: "Reprocessing ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "ReprocessingName", label: "Reprocessing", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "ReprocessingProjectIdentifier", label: "Project ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "ReprocessingProjectName", label: "Project", filter: TextBoxFilter, operators: getTextFilter(false) },
                            { name: "RelatedAcquisitions", label: "Acquisitions", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "ReprocessingOperator", label: "Reprocessing Operator", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "ReprocessingType", label: "Reprocessing Type", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "BasicRelevantDate", label: "Basic Relevant Date", filter: DateFilter, operators: getDateFilter(true) },
                            { name: "InterpretativeRelevantDate", label: "Interp Relevant Date", filter: DateFilter, operators: getDateFilter(true) },
                            { name: "BasicReleaseDate", label: "Basic Release Date", filter: DateFilter, operators: getDateFilter(true) },
                            { name: "InterpretativeReleaseDate", label: "Interp Release Date", filter: DateFilter, operators: getDateFilter(true) },
                            { name: "AcquisitionAliases", label: "Acquisition Aliases", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "RelatedTitles", label: "Titles", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "RelatedBasins", label: "Basins", filter: TextBoxFilter, operators: getTextFilter(true) },
                            { name: "Comments", label: "Comments", filter: TextBoxFilter, operators: getTextFilter(true) },
                        ]}
                    />
                </div>
                <div className="col-auto">
                    <div className="mt-2 float-right">
                        <CopyODataLinkView title="Reprocessing OData" toolTip="Reprocessing" oDataPath="/odata/v1/public/nopims/reprocessing/PublicNopimsReprocessings" />
                    </div>
                </div>
            </div>
            <ODataReader
                url="odata/v1/public/nopims/reprocessing/PublicNopimsReprocessings"
                showContentAsBusyElementId="ReprocessingSearchResultsGrid"
                dataState={gridState}
                onDataReceived={onDataChange}
                mapDataRow={mapDataRow}
                requiresAuth={false}
            />
            <div className="mb-4">
                <Grid
                    id="ReprocessingSearchResultsGrid"
                    sortable
                    pageable={{ pageSizes: [10, 20, 50, 100], pageSizeValue: pageSize, responsive: false }}
                    onPageChange={(e: GridPageChangeEvent) => {
                        setPageSize(e.page.take);
                        setGridDataState({ ...gridState, take: e.page.take, skip: e.page.skip });
                    }}
                    resizable
                    navigatable
                    {...gridState}
                    data={data}
                    onDataStateChange={(e: GridDataStateChangeEvent) => setGridDataState(e.dataState)}
                >
                    <GridColumn
                        locked={true}
                        width={60}
                        headerCell={() => (
                            <button
                                type="button"
                                className="btn btn-outline-secondary btn-sm"
                                onClick={() => props.onAdd(data.data)}
                                title={`Click to add all Projects on this page to the Selected Projects.`}
                            >
                                <IoAddCircleOutline size={20} />
                            </button>
                        )}
                        cell={(gridCellProps) => (
                            <CustomGridCell gridCellProps={gridCellProps} className={gridCellProps.className + " py-1"} style={gridCellProps.style}>
                                {!props.selectedItems.find((i) => i.ReprocessingProjectId === (gridCellProps.dataItem as IReprocessingSearchDataRow).ReprocessingProjectId) ? (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() => props.onAdd([gridCellProps.dataItem])}
                                        title="Click to add this Project to the Selected Projects."
                                    >
                                        <IoAddCircleOutline size={20} />
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={() => props.onRemove(gridCellProps.dataItem)}
                                        title="Click to remove this Project from the Selected Projects."
                                    >
                                        <IoCheckmarkCircle size={20} color="green" />
                                    </button>
                                )}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="ReprocessingIdentifier" title="Reprocessing ID" width={150} />
                    <GridColumn field="ReprocessingName" title="Reprocessing" width={400} />
                    <GridColumn field="ReprocessingProjectIdentifier" title="Project ID" width={150} />
                    <GridColumn field="ReprocessingProjectName" title="Project" width={400} />
                    <GridColumn
                        field="RelatedAcquisitions"
                        title="Acquisitions"
                        width={400}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props} className="k-command-cell">
                                {props.dataItem.RelatedAcquisitions && (
                                    <ListSummaryView
                                        fill={true}
                                        maxChildrenDisplayCount={5}
                                        children={props.dataItem
                                            .RelatedAcquisitions!.split(", ")
                                            .sort(compare)
                                            .map((acquisition: string) =>
                                                acquisition!.length <= maxTextLength
                                                    ? { displayElement: generateListSummaryViewChild(acquisition) }
                                                    : {
                                                          displayElement: generateListSummaryViewChild(acquisition, StringHelper.truncate(acquisition!, maxTextLength)),
                                                          popupDisplayElement: generateListSummaryViewChild(acquisition),
                                                      }
                                            )}
                                        title="Acquisitions"
                                    ></ListSummaryView>
                                )}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="ReprocessingOperator" title="Reprocessing Operator" width={250} />
                    <GridColumn field="ReprocessingType" title="Reprocessing Type" width={250} />
                    <GridColumn field="BasicRelevantDate" title="Basic Relevant Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="InterpretativeRelevantDate" title="Interp Relevant Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="BasicReleaseDate" title="Basic Release Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="InterpretativeReleaseDate" title="Interp Release Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn
                        field="AcquisitionAliases"
                        title="Acquisition Aliases"
                        width={400}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props} className="k-command-cell">
                                {props.dataItem.AcquisitionAliases && (
                                    <ListSummaryView
                                        fill={true}
                                        maxChildrenDisplayCount={5}
                                        children={props.dataItem
                                            .AcquisitionAliases!.split(", ")
                                            .sort(compare)
                                            .map((alias: string) =>
                                                alias!.length <= maxTextLength
                                                    ? { displayElement: generateListSummaryViewChild(alias) }
                                                    : {
                                                          displayElement: generateListSummaryViewChild(alias, StringHelper.truncate(alias!, maxTextLength)),
                                                          popupDisplayElement: generateListSummaryViewChild(alias),
                                                      }
                                            )}
                                        title="Acquisition Aliases"
                                    ></ListSummaryView>
                                )}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="RelatedTitles"
                        title="Titles"
                        width={250}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props} className="k-command-cell">
                                {props.dataItem.RelatedTitles && (
                                    <ListSummaryView
                                        fill={true}
                                        maxChildrenDisplayCount={5}
                                        children={props.dataItem
                                            .RelatedTitles!.split(", ")
                                            .sort(compare)
                                            .map((acquisition: string) =>
                                                acquisition!.length <= maxTextLength
                                                    ? { displayElement: generateListSummaryViewChild(acquisition) }
                                                    : {
                                                          displayElement: generateListSummaryViewChild(acquisition, StringHelper.truncate(acquisition!, maxTextLength)),
                                                          popupDisplayElement: generateListSummaryViewChild(acquisition),
                                                      }
                                            )}
                                        title="Titles"
                                    ></ListSummaryView>
                                )}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="RelatedBasins"
                        title="Basins"
                        width={250}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props} className="k-command-cell">
                                {props.dataItem.RelatedBasins && (
                                    <ListSummaryView
                                        fill={true}
                                        maxChildrenDisplayCount={5}
                                        children={props.dataItem
                                            .RelatedBasins!.split(", ")
                                            .sort(compare)
                                            .map((acquisition: string) =>
                                                acquisition!.length <= maxTextLength
                                                    ? { displayElement: generateListSummaryViewChild(acquisition) }
                                                    : {
                                                          displayElement: generateListSummaryViewChild(acquisition, StringHelper.truncate(acquisition!, maxTextLength)),
                                                          popupDisplayElement: generateListSummaryViewChild(acquisition),
                                                      }
                                            )}
                                        title="Basins"
                                    ></ListSummaryView>
                                )}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="Comments"
                        title="Comments"
                        width={400}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props} className="k-command-cell">
                                <CommentSummaryView title="Comments" comment={props.dataItem.Comments} />
                            </CustomGridCell>
                        )}
                    />
                </Grid>
            </div>
        </>
    );
}
