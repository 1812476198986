import * as ReactDOM from "react-dom";
import { CompositeFilterDescriptor, DataResult, FilterDescriptor, State, toODataString } from "@progress/kendo-data-query";
import { getServicesUri } from "../../api/uriHelper";
import { logError } from "../LogHelper";
import { useRef, useState, useEffect, ReactNode } from "react";
import SimpleValidationMessageView from "../validation/SimpleValidationMessageView";
import { clone } from "lodash";
import { getSessionCorrelation } from "../../api/clientFactory";
import msalAuthService from "../../infrastructure/msalAuthService";

interface IDataLoaderProps {
    url: string;
    requiresAuth: boolean;
    query?: string | undefined;
    dataState: State;
    /**
     * Allows for additional non-user filters to be applied, set at the Top Filter level as an AND.
     */
    additionalFilters?: CompositeFilterDescriptor | undefined;
    onDataReceived: (data: DataResult) => void;
    mapDataRow: (dataRow: any) => any;
    showContentAsBusyElementId: string;
    changeToken?: Date | string | number | undefined;

    /**
     * Allows for a set of custom expression to be supplied.
     * @param {string} name - must be prefixed with "@" and ensure this matches the field used supplied by the grid filter
     * @param {(value: any, operator: any) => string | undefined} expression - the expression to execute to generate the custom expression to use in the OData query; return as 'undefined' if you do not want a custom expression to be rendered.
     */
    customFilterExpressions?: Array<ICustomFilterExpression> | undefined;
}

export interface ICustomFilterExpression {
    name: string;
    expression: (value: any, operator: any) => string | undefined;
}

export default function ODataReader(props: IDataLoaderProps): JSX.Element {
    return (
        <ODataValidator dataState={props.dataState} onDataReceived={props.onDataReceived}>
            <ODataLoaderInternal
                url={props.url}
                requiresAuth={props.requiresAuth}
                query={props.query}
                showContentAsBusyElementId={props.showContentAsBusyElementId}
                dataState={props.dataState}
                onDataReceived={props.onDataReceived}
                mapDataRow={props.mapDataRow}
                changeToken={props.changeToken}
                customFilterExpressions={props.customFilterExpressions}
                additionalFilters={props.additionalFilters}
            />
        </ODataValidator>
    );
}

function ODataValidator(props: { dataState: State; onDataReceived: (data: DataResult) => void; children?: ReactNode }): JSX.Element {
    const maximumFilters = 15;
    const maximumValueLength = 100;

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (props.dataState.filter && filterExpressionContainsLongValues(props.dataState.filter!)) {
            setErrorMessage(`The filter criteria contains values that exceed ${maximumValueLength} characters; please adjust your filter criteria and try again.`);
            return;
        }
        if (props.dataState.filter && filterExpressionContainsTooManyNodes(props.dataState.filter!)) {
            setErrorMessage("Your filter criteria is too complex; please adjust your filter criteria and try again.");
            return;
        }
        setErrorMessage(undefined);
    }, [props.dataState, props.dataState.filter]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (errorMessage) props.onDataReceived({ data: [], total: 0 });
    }, [errorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {errorMessage && (
                <div className="mb-2">
                    <SimpleValidationMessageView message={errorMessage!} />
                </div>
            )}
            {!errorMessage && props.children}
        </>
    );

    function filterExpressionContainsLongValues(compositeFilterDescriptor: CompositeFilterDescriptor): boolean {
        // verifies the length of values supplied aren't too long

        // - verify filters
        const filterDescriptorResults = compositeFilterDescriptor.filters.filter((f: any) => f.value && f.value.toString().length > maximumValueLength);
        if (filterDescriptorResults.length > 0) return true;

        // - verify composite filters
        if (!compositeFilterDescriptor.filters) return false;
        const compositeFilterDescriptorResults = compositeFilterDescriptor.filters.filter((f: any) => f.filters && filterExpressionContainsLongValues(f));
        if (compositeFilterDescriptorResults.length > 0) return true;

        // - all good!
        return false;
    }

    function filterExpressionContainsTooManyNodes(compositeFilterDescriptor: CompositeFilterDescriptor): boolean {
        // verifies we don't have too many filters defined
        const numberOfFilters = getNumberOfFiltersUnderCompositeFilterDescriptor(compositeFilterDescriptor);
        return numberOfFilters > maximumFilters;
    }
}

function ODataLoaderInternal(props: IDataLoaderProps): JSX.Element {
    // the state supplies the filters, paging and sorting
    // provides de-bouncing of events, i.e.
    // - if a request is running another request will not be made until the the running request has completed
    // - when the request is completed it will check to verify if another request is needed

    const requestUrl = getUrl(props.dataState, props.query, props.customFilterExpressions, props.additionalFilters);
    const lastSuccessfulQuery = useRef<string>("");
    const runningQuery = useRef<string>("");
    const isMounted = useRef<boolean>(true);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    });

    function fetchRequest(requestInit?: RequestInit): void {
        fetch(requestUrl, requestInit)
            .then((response) => {
                if (response.ok) return response.json();
                throw new Error();
            })
            .then((json) => {
                // mark as successful and clear the running query
                lastSuccessfulQuery.current = runningQuery.current;
                runningQuery.current = "";

                // if we've since unmounted then abandon the operation
                if (!isMounted.current) return;

                // request a new query if it doesn't match what the user has now asked for
                const requestedQuery = getChangeTracker(getUrl(props.dataState, props.query, props.customFilterExpressions, props.additionalFilters), props.changeToken);
                if (requestedQuery !== lastSuccessfulQuery.current) {
                    requestDataIfNeeded();
                    return;
                }

                // apply the data
                const data: any[] = json.value;
                const total = json["@odata.count"];
                log(`Completed: ${lastSuccessfulQuery.current} (${data.length} rows for ${total} total)`);
                props.onDataReceived({
                    data: data.map((dr: any) => props.mapDataRow(dr)),
                    total: total,
                });
            })
            .catch((e) => {
                // on failure, stop all queries until a change is requested
                // the user will be presented with an empty grid
                // we can expand on this later if needed
                lastSuccessfulQuery.current = runningQuery.current;
                runningQuery.current = "";
                props.onDataReceived({ data: [], total: 0 });
                logError(e); // <-- this will ensure we see the events in application insights
            });
    }

    function requestDataIfNeeded(): void {
        // skip the query if one is running or it's the same as one that has completed
        if (runningQuery.current || getChangeTracker(requestUrl, props.changeToken) === lastSuccessfulQuery.current) {
            return;
        }

        // initiate a fetch operation to load the data
        runningQuery.current = getChangeTracker(requestUrl, props.changeToken);
        log(`Querying: ${runningQuery.current}`);

        // get the access token
        // - the msal auth service caches the token, and is surprisingly fast on fetch
        // - the danger of caching it locally is it may expire, so best to get it from the service as it will know when to refresh the token

        if (props.requiresAuth) {
            msalAuthService.getAccessToken().then((accessToken) => {
                fetchRequest({
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Session-Correlation": getSessionCorrelation(),
                        Authorization: "Bearer " + accessToken,
                    },
                });
            });
        } else {
            fetchRequest({
                method: "GET",
                headers: {
                    "Session-Correlation": getSessionCorrelation(),
                },
            });
        }
    }

    // initiate load
    requestDataIfNeeded();

    // apply a localised loading panel whilst a query is running
    return runningQuery.current ? <LoadingPanel elementId={props.showContentAsBusyElementId} /> : <></>;

    function LoadingPanel(props: { elementId: string }): JSX.Element {
        const loadingPanel = (
            <div className="k-loading-mask">
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image" />
                <div className="k-loading-color" />
            </div>
        );

        let element: HTMLElement | null | undefined = document.getElementById(props.elementId);

        // when attaching to a grid, attempt to find and use the content element as this appears to render faster
        element = element?.querySelector(".k-grid-content") ?? element;

        return element ? ReactDOM.createPortal(loadingPanel, element) : loadingPanel;
    }

    function getUrl(
        dataState: State,
        additionalQuery: string | undefined,
        customFilterExpressions: Array<ICustomFilterExpression> | undefined,
        additionalFilters: CompositeFilterDescriptor | undefined
    ): string {
        if (!dataState || !dataState.filter) throw new Error();

        // so - the Kendo 'toODataString' has lots of limitations
        // to avoid generating malformed requests based on incomplete query structures, we remove any empty expression groups
        // this is to work around a kendo bug; it is clear they have not performed rudimentary testing
        // there are also many expressions they cannot/do not generate properly, so we need to patch in 'custom expressions'
        // yes, it is very very hacky but has been done out of necessity until we can get kendo to help us with this
        // unfortunately their documentation is severely lacking
        // TODO work with Kendo on a better solution here

        const customExpressions = customFilterExpressions ? getCustomExpressions(dataState.filter!, customFilterExpressions) : [];

        // filter the filters, keeping only what we want to use, and inject placeholders for custom expressions
        const filter = getFilter(dataState.filter!, customExpressions, additionalFilters);

        // generate OData query string (using Kendo's standard logic)
        const dataStateClone = clone(dataState);
        dataStateClone.filter = filter;
        let query = toODataString(dataStateClone);

        // inject custom expressions
        // - find and replace expression placeholders
        query = replaceCustomExpressionPlaceholders(query, customExpressions);

        // add any additional query options
        // - this can be used to expand navigation properties
        if (additionalQuery) query = query + "&" + additionalQuery;

        // add 'count=true' as this is needed for the paging control
        query = query + "&$count=true";

        // return the final query
        query = getServicesUri() + "/" + props.url + "?" + query;
        //console.log(query);
        return query;

        function getCustomExpressions(
            compositeFilterDescriptor: CompositeFilterDescriptor,
            customFilterExpressions: Array<ICustomFilterExpression>
        ): { key: string; name: string; expression: string | undefined }[] {
            // generates OData expressions for any supplied custom filters
            // the key identifies the custom expression instance

            if (!compositeFilterDescriptor) throw new Error("compositeFilterDescriptor is required!");
            if (!customFilterExpressions) throw new Error("customFilterExpressions is required!");
            if (customFilterExpressions.find((e) => !e.name.startsWith("@"))) throw new Error("customFilterExpressions must all use a name that starts with '@'!");
            if (customFilterExpressions.find((e) => customFilterExpressions.filter((e2) => e.name === e2.name).length > 1)) throw new Error("customFilterExpression names must be unique!");

            let result: { key: string; name: string; expression: string | undefined }[] = [];

            // walk the supplied filter hierarchy and detect where we need to build custom expressions

            for (const f of compositeFilterDescriptor.filters) {
                if (isFilterDescriptor(f)) {
                    // process filters
                    // - ignore expressions that won't require a custom expression
                    // - only add unique expressions
                    const fd = f as FilterDescriptor;
                    const customFilterExpression = customFilterExpressions.find((cfe) => cfe.name === fd.field);
                    if (!customFilterExpression) continue;
                    const expression = customFilterExpression.expression(fd.value, fd.operator);
                    const key = getCustomExpressionKey(fd);
                    if (!result.find((e) => e.key === key)) result.push({ key: key, name: customFilterExpression.name, expression: expression });
                }

                // process composite filters (filter groups)
                // - recursive
                // - ignore empty groups
                // - only add unique expressions
                if (isCompositeFilterDescriptor(f)) {
                    const cf = f as CompositeFilterDescriptor;
                    if (getNumberOfFiltersUnderCompositeFilterDescriptor(cf) === 0) continue;
                    const childResult = getCustomExpressions(cf, customFilterExpressions);
                    for (const r of childResult) {
                        if (result.find((e) => e.key === r.key)) continue;
                        result.push(r);
                    }
                }
            }

            return result;
        }

        function getFilter(
            compositeFilterDescriptor: CompositeFilterDescriptor,
            customExpressions: { key: string; name: string; expression: string | undefined }[],
            additionalFilters: CompositeFilterDescriptor | undefined
        ): CompositeFilterDescriptor {
            if (!compositeFilterDescriptor) throw new Error("compositeFilterDescriptor is required!");
            if (!customExpressions) throw new Error("customExpressions is required!");

            const result: CompositeFilterDescriptor = { logic: compositeFilterDescriptor.logic, filters: [] };

            // walk the supplied filter hierarchy and build an alternate hierarchy

            for (const f of compositeFilterDescriptor.filters) {
                if (isFilterDescriptor(f)) {
                    // process filters
                    // - inject placeholder expressions for custom expressions
                    // - ignore custom expressions that return 'undefined'
                    // - add normal field expressions
                    const fd = f as FilterDescriptor;
                    const fieldName = fd.field as string;
                    if (!fieldName) throw new Error("The field name must contain a value!");
                    // custom expression
                    const ce = customExpressions.find((ce2) => ce2.key === getCustomExpressionKey(fd));
                    if (fieldName.startsWith("@") && !ce) throw new Error(`A custom expression is missing for field '${fieldName}'!`);
                    if (ce) {
                        if (!ce.expression) continue;
                        result.filters.push({ field: fd.field, operator: "eq", value: ce.key } as FilterDescriptor);
                        continue;
                    }
                    // add it!
                    result.filters.push(f);
                }

                // process composite filters (filter groups)
                // - recursive
                // - ignore empty groups
                if (isCompositeFilterDescriptor(f)) {
                    const cf = f as CompositeFilterDescriptor;
                    const children = getFilter(cf, customExpressions, undefined);
                    if (getNumberOfFiltersUnderCompositeFilterDescriptor(children) === 0) continue;
                    result.filters.push(children);
                }
            }

            // If we have additional filters, apply at the top level.
            if (additionalFilters) {
                if (result.filters.length === 0) return additionalFilters;
                else return { logic: "and", filters: [result, additionalFilters] };
            }

            return result;
        }

        function getCustomExpressionKey(filterDescriptor: FilterDescriptor): string {
            // generates a unique key for the supplied expression
            // - one considered alternative to the approach used below is to generate an alphanumeric sequence from the raw hex data, which would avoid being escaped
            return JSON.stringify({ name: filterDescriptor.field!, operator: filterDescriptor.operator, value: filterDescriptor.value });
        }

        function replaceCustomExpressionPlaceholders(query: string, customExpressions: { key: string; name: string; expression: string | undefined }[]): string {
            // inject custom expressions
            // - find and replace expressions based on the the keys embedded in the placeholder expressions

            /*
            // for debugging only!
            console.log("BEFORE INJECTION");
            console.log(query);
            console.log(customExpressions);
            */

            let result = query;
            for (const ce of customExpressions) {
                // don't inject custom expressions where there is no expression to inject
                if (!ce.expression) continue;

                // replace all instances of the given expression
                // - note that kendo uses 'encodeURIComponent' to format the key
                const placeholderExpression = ce.name + " eq '" + encodeURIComponent(ce.key) + "'";
                const newQuery = result.replaceAll(placeholderExpression, ce.expression);
                if (newQuery === query) throw new Error(`The custom expression for '${ce.name}' was not replaced!`);
                result = newQuery;
            }

            /*
            // for debugging only!
            console.log("AFTER INJECTION");
            console.log(result);
            */

            return result;
        }
    }

    function getChangeTracker(requestUrl: string, changeToken: Date | string | number | undefined) {
        return requestUrl + `(${changeToken ?? ""})`;
    }
}

function getNumberOfFiltersUnderCompositeFilterDescriptor(compositeFilterDescriptor: CompositeFilterDescriptor): number {
    if (!compositeFilterDescriptor.filters || compositeFilterDescriptor.filters.length === 0) return 0;

    let result = 0;
    for (let i = 0; i < compositeFilterDescriptor.filters.length; i++) {
        const f = compositeFilterDescriptor.filters[i];

        // count FilterDescriptors
        if (isFilterDescriptor(f)) result++;

        // count FilterDescriptors under CompositeFilterDescriptors
        if (isCompositeFilterDescriptor(f)) {
            const cf = f as CompositeFilterDescriptor;
            result += getNumberOfFiltersUnderCompositeFilterDescriptor(cf);
        }
    }
    return result;
}

function isFilterDescriptor(filter: CompositeFilterDescriptor | FilterDescriptor | undefined): boolean {
    // the FilterDescriptor contains a mandatory 'Operator' field that we check for
    if (!filter) return false;
    const f: any = filter!;
    return !!f.operator;
}

function isCompositeFilterDescriptor(filter: CompositeFilterDescriptor | FilterDescriptor | undefined): boolean {
    // the CompositeFilterDescriptor contains a mandatory 'Logic' field that we check for
    if (!filter) return false;
    const f: any = filter!;
    return !!f.logic;
}

const showDebugMessages: boolean = false;

function log(message: string) {
    if (!showDebugMessages) return;
    console.debug("ODataReader: " + message);
}
