// framework
import { useState, useEffect } from "react";
// kendo
import { DataResult, State } from "@progress/kendo-data-query";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridDataStateChangeEvent, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { FilterChangeEvent, NumericFilter } from "@progress/kendo-react-data-tools";
// common
import ODataReader from "../../../common/odata/ODataReader";
import { useUserToken } from "../../../common/shell/shellHelper";
import GridFilter from "../../../common/kendoGrid/GridFilter";
import { getNumericFilter, getTextFilter } from "../../../common/kendoGrid/GridFilterOptions";
import { TextBoxFilter } from "../../../common/kendoGrid/GridFilterTextBoxFilter";
// models
import { ISlideAndResidueDataRow } from "./models";

interface IProps {
    selectionVersion: number;
}

export default function Controller(props: IProps) {
    const userToken = useUserToken();

    const [pageSize, setPageSize] = useState(10);
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
            {
                field: "WellName",
                operator: "contains",
                value: "",
            },
        ],
    };

    const [gridState, setGridDataState] = useState<State>({
        sort: [
            { field: "WellName", dir: "asc" },
            { field: "BoreholeName", dir: "asc" },
            { field: "Name", dir: "asc" },
        ],
        take: pageSize,
        skip: 0,
        filter: initialFilter,
    });

    const [filter, setFilter] = useState<CompositeFilterDescriptor>(initialFilter);
    function onFilterChange(e: FilterChangeEvent): void {
        setFilter(e.filter);
        setGridDataState({ ...gridState, filter: e.filter });
    }

    const [data, setData] = useState<DataResult>({ data: [], total: 0 });
    function onDataChange(data: DataResult): void {
        setData(data);
    }

    function mapDataRow(dataRow: any): ISlideAndResidueDataRow {
        return dataRow;
    }

    // ensure we don't get stuck on an empty grid page
    useEffect(() => {
        if (data.data.length === 0 && data.total > 0 && gridState && gridState.skip! > 0) setGridDataState({ ...gridState, skip: 0 });
    }, [data, gridState]);

    // return
    return (
        <>
            <GridFilter
                value={filter}
                onChange={onFilterChange}
                fields={[
                    { name: "Uwi", label: "Well ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "WellName", label: "Well", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "Ubhi", label: "Borehole ID", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "BoreholeName", label: "Borehole", filter: TextBoxFilter, operators: getTextFilter(false) },
                    { name: "Jurisdiction", label: "State", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "Basin", label: "Basin", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "Name", label: "Sample Name", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "TopDepth", label: "Top Depth", filter: NumericFilter, operators: getNumericFilter(true) },
                    { name: "BottomDepth", label: "Bottom Depth", filter: NumericFilter, operators: getNumericFilter(true) },
                    { name: "DepthUnit", label: "Units", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "AnalysisType", label: "Analysis", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "SampleTestType", label: "Test Type", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "MaterialType", label: "Source Material", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "SampleId", label: "Sample ID", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "MediaType", label: "Media Type", filter: TextBoxFilter, operators: getTextFilter(true) },
                    { name: "PalaeoBoxNumber", label: "Box Number", filter: TextBoxFilter, operators: getTextFilter(true) },
                ]}
            />
            {userToken && (
                <ODataReader
                    url={`odata/v1/public/nopims/well/PublicNopimsWells/GetSlideAndResiduesForSelection(userToken=${userToken})`}
                    showContentAsBusyElementId="WellSlideAndResidueSearchResultsGrid"
                    dataState={gridState}
                    onDataReceived={onDataChange}
                    mapDataRow={mapDataRow}
                    changeToken={props.selectionVersion}
                    requiresAuth={false}
                />
            )}
            <div className="mb-4">
                <Grid
                    id="WellSlideAndResidueSearchResultsGrid"
                    sortable
                    pageable={{ pageSizes: [10, 20, 50, 100], pageSizeValue: pageSize, responsive: false }}
                    onPageChange={(e: GridPageChangeEvent) => {
                        setPageSize(e.page.take);
                        setGridDataState({ ...gridState, take: e.page.take, skip: e.page.skip });
                    }}
                    resizable
                    navigatable
                    {...gridState}
                    data={data}
                    onDataStateChange={(e: GridDataStateChangeEvent) => setGridDataState(e.dataState)}
                >
                    <GridColumn field="Uwi" filter="text" title="Well ID" width={150} />
                    <GridColumn field="WellName" filter="text" title="Well" width={250} />
                    <GridColumn field="Ubhi" filter="text" title="Borehole ID" width={150} />
                    <GridColumn field="BoreholeName" filter="text" title="Borehole" width={250} />
                    <GridColumn field="Jurisdiction" title="State" width={200} />
                    <GridColumn field="Basin" title="Basin" width={250} />
                    <GridColumn field="Name" filter="text" title="Sample Name" width={300} />
                    <GridColumn field="TopDepth" title="Top Depth" format={"{0:N}"} width={150} />
                    <GridColumn field="BottomDepth" title="Bottom Depth" format={"{0:N}"} width={150} />
                    <GridColumn field="DepthUnit" filter="text" title="Units" width={100} />
                    <GridColumn field="AnalysisType" filter="text" title="Analysis" width={200} />
                    <GridColumn field="SampleTestType" filter="text" title="Test Type" width={200} />
                    <GridColumn field="MaterialType" filter="text" title="Source Material" width={200} />
                    <GridColumn field="SampleId" filter="text" title="Sample ID" width={150} />
                    <GridColumn field="MediaType" filter="text" title="Media Type" width={150} />
                    <GridColumn field="PalaeoBoxNumber" filter="text" title="Box Number" width={200} />
                </Grid>
            </div>
        </>
    );
}
