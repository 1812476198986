// framework
import { Route, Switch } from "react-router-dom";
// common
import * as ShellHelper from "./common/shell/shellHelper";
// layout
import Layout from "./shell/components/Layout";
// footer pages
import Disclaimer from "./shell/components/footer/Disclaimer";
import Accessibility from "./shell/components/footer/Accessibility";
// error pages
import TestErrorPage from "./shell/components/TestErrorPage";
import NotFound from "./shell/components/error/NotFoundPage";
import Conflict from "./shell/components/error/ConflictPage";
import Unauthorised from "./shell/components/error/UnauthorisedPage";
import InternalServerError from "./shell/components/error/InternalServerErrorPage";
import ServiceUnavailable from "./shell/components/error/ServiceUnavailablePage";
// user pages
import HomePage from "./home/components/HomePage";
import TitleSearchController from "./opggs/title/titleSearch/components/TitleSearchController";
import TitleDetailsController from "./opggs/title/titleDetails/components/TitleDetailsController";
import ApplicationTrackingController from "./opggs/application/opggsApplicationTracking/components/OpggsApplicationTrackingController";
import OpggsApplicationTrackingDetailsController from "./opggs/application/opggsApplicationTrackingDetails/components/OpggsApplicationTrackingDetailsController";
import LicenceSearchController from "./oei/licence/search/components/LicenceSearchController";
import LicenceDetailsController from "./oei/licence/details/components/LicenceDetailsController";
import MapController from "./map/components/MapController";
import OpenTitleController from "./open/title/components/OpenTitleController";
import OpenLicenceController from "./open/licence/components/OpenLicenceController";
import OeiApplicationTrackingController from "./oei/application/oeiApplicationTracking/components/OeiApplicationTrackingController";
import OeiApplicationTrackingDetailsController from "./oei/application/oeiApplicationTrackingDetails/components/OeiApplicationTrackingDetailsController";
import StorageFormationsListController from "./opggs/storageFormation/storageFormationsList/components/StorageFormationsListController";
import { buildRoutes } from "./infrastructure/routeBuilder";

function App() {
    const showTestErrorPage = process.env.REACT_APP_SHOW_TEST_ERROR_PAGE === "true";

    const featureSwitches = ShellHelper.useFeatureSwitch();
    const routes = buildRoutes(featureSwitches);

    // TODO convert everything in here to the routeBuilder approach

    return (
        <Layout>
            <Switch>
                {/* register routes here - this renders the content into the layout page based on the supplied route, this will process in order */}
                <Route path="/" exact children={<HomePage />} />
                <Route path="/Opggs/Title" exact children={<TitleSearchController />} />
                <Route path="/Opggs/Title/:titleId" children={<TitleDetailsController />} />
                <Route path="/Opggs/ApplicationTracking" exact children={<ApplicationTrackingController />} />
                <Route path="/Opggs/ApplicationTracking/:applicationId" children={<OpggsApplicationTrackingDetailsController />} />
                <Route path="/Opggs/StorageFormation" exact children={<StorageFormationsListController />} />
                <Route path="/Oei/Licence/Search" exact children={<LicenceSearchController />} />
                <Route path="/Oei/Licence/:licenceId" children={<LicenceDetailsController />} />
                <Route path="/Oei/ApplicationTracking" exact children={<OeiApplicationTrackingController />} />
                <Route path="/Oei/ApplicationTracking/:applicationId" children={<OeiApplicationTrackingDetailsController />} />

                {routes}

                <Route path="/Map" exact children={<MapController />} />
                <Route path="/Open/Title" children={<OpenTitleController />} />
                <Route path="/Open/Licence" children={<OpenLicenceController />} />
                {/* footer pages */}
                <Route path="/Accessibility" exact children={<Accessibility />} />
                <Route path="/Disclaimer" exact children={<Disclaimer />} />
                {/* error pages */}
                {showTestErrorPage && <Route path="/TestErrorPage" exact children={<TestErrorPage />} />}
                <Route path="/NotFound" exact children={<NotFound />} />
                <Route path="/Conflict" exact children={<Conflict />} />
                <Route path="/Unauthorised" exact children={<Unauthorised />} />
                <Route path="/InternalServerError" exact children={<InternalServerError />} />
                <Route path="/ServiceUnavailable" exact children={<ServiceUnavailable />} />
                {/* implicit not-found page */}
                <Route children={<NotFound />} />
            </Switch>
        </Layout>
    );
}

export default App;
